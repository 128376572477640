import { Controller } from "@hotwired/stimulus";
import { FetchRequest } from "@rails/request.js"
import { useClickOutside } from 'stimulus-use'

export default class extends Controller {
  static targets = ['editor', 'form']

  static values = {
    url: String,
    editing: Boolean,
    outable: Boolean
  }

  connect() {
    useClickOutside(this)

    var elems = this.element.querySelectorAll("[colwidth]");
    [].forEach.call(elems, function(el) {
      const width = el.getAttribute('colwidth')
      const newWidth = `width: ${width}px !important`;
      console.log('yeyeye', width, newWidth)
      el.style = newWidth
    });

  }

  clickOutside(event) {
    if (this.editingValue) {
      // console.log(`target`, event.target)
      const canvas = event.target.closest(".canvas");
      const canvasClick = Boolean(canvas);

      if (canvasClick && this.outableValue) {
        // console.log(`go ${this.urlValue}`)
        // Turbo.visit(`${this.urlValue}`, { turbo: true, acceptsStreamResponse: true })
      }
    }
  }

  visit(e) {
    console.log('visiting')
  }

  open(e) {
    if (this.editingValue) {
      // console.log('already here')
      // console.log(`editing ${this.editingValue}`)
    } else {

      const tools = event.target.closest(".sectool");
      const toolsClick = Boolean(tools);

      const archi = event.target.closest(".archi");
      const archiClick = Boolean(archi);

      if (toolsClick) {
        console.log('tools click')
      // } else if (archiClick) {
      //   console.log('archi click')
      } else {
        e.stopPropagation()
        var elems = document.querySelectorAll(".section");
        [].forEach.call(elems, function(el) {
          // el.classList.remove("focused");
          el.classList.remove("highlighted");
        });
        this.element.classList.add('focused')
        console.log(`go ${this.urlValue}/edit`)
        Turbo.visit(`${this.urlValue}/edit`, { turbo: true, acceptsStreamResponse: true })
      }
    }
  }

  // async deleteEntry() {
  //   const request = new FetchRequest('delete', this.urlValue)
  //   const response = await request.perform()
  //   if (response.ok) {
  //     this.element.remove()
  //   } else {
  //   }
  // }

  // async submitForm(form) {
  //   const request = new FetchRequest('patch', form.action, {
  //     body: new FormData(form)
  //   })

  //   return await request.perform()
  // }
}