import Dropdown from '@stimulus-components/dropdown'
import { useClickOutside } from 'stimulus-use'

export default class extends Dropdown {
  static targets = ['menu']
  static values = {
    focusable: String,
  }

  clickOutside(event) {
    // example to close a modal
    const soarer = document.getElementById('soarer')
    soarer.innerHTML = ''
    // console.log('outsided soarer', event.target)
    // console.log('outsided soarer', this.menuTarget)
  }

  connect() {
    super.connect()
    // useClickOutside(this)
  }

  toggle(event) {
    // console.log('menu', this.menuTarget)
    // const menuHTML = this.menuTarget.cloneNode(true)
    // const soarer = document.getElementById('soarer')
    // event.preventDefault()
    // // soarer.innerHTML = menuHTML.innerHTML
    // soarer.innerHTML = ''
    // soarer.appendChild(menuHTML)

    // menuHTML.classList.remove('hidden')
    
    // // let x = event.layerX
    // // let y = event.layerY + 10
    // let x = event.pageX
    // let y = event.pageY - 10

    // soarer.style.left = `${x}px`;
    // soarer.style.top = `${y}px`;

    super.toggle()
    
    if (this.focusableValue) {
      // console.log('yaw')
      // console.log(this.focusableValue)
      const focusableElement = this.element.querySelector(`#${this.focusableValue}`)
      // focusableElement.selectAll()
      // console.log(focusableElement)
      if (focusableElement) {
        focusableElement.focus()
        focusableElement.select()
      }
      // const range = document.createRange();
      // range.selectNodeContents(focusableElement);
      // const selection = window.getSelection();
      // selection.removeAllRanges();
      // selection.addRange(range);
    }
  }

  hide(event) {
    super.hide(event)
  }
}