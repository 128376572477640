import { Controller } from "@hotwired/stimulus"
import React from 'react'
import { createRoot } from "react-dom/client"
import App from "./../app/index.jsx"

export default class extends Controller {
  static values = {
    items: Array,
    edges: Array,
  }
  connect() {
    console.log('ok react')
    const root = createRoot(this.element)
    root.render(
      <App items={this.itemsValue} iedges={this.edgesValue}/>
    )
  }
}
