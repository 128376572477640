import { Controller } from "@hotwired/stimulus"

window.scrollPositions = []

export default class extends Controller {
  static values = {
    scroll: Boolean
  }
  connect() {
    // console.log(`scroll-${this.element.id}`, localStorage.getItem(`scroll-${this.element.id}`))
    if (this.scrollValue) {
      this.element.scrollTop = localStorage.getItem(`scroll-${this.element.id}`)
    }
    this.element.addEventListener("scroll", (event) => {
      window.scrollPositions[this.element.id] = this.element.scrollTop
    });
  }

  scroll() {

  }

  disconnect() {
    localStorage.setItem(`scroll-${this.element.id}`, window.scrollPositions[this.element.id]);
  }
}
