import { Controller } from "@hotwired/stimulus"
import { FetchRequest  } from "@rails/request.js"

export default class extends Controller {

  static values = {
    url: String,
    name: String,
    prop: String,
    select: Boolean,
    blockUrl: String
  }

  connect() {
    console.log('connect ok')
    let timeout = null;
    
    if (this.selectValue) {
      this.selectAll()
    }

    this.element.addEventListener('keydown', (e) => {
      if (e.key == 'Enter') {
        e.preventDefault()
        this.createNextBlock()
      }
      console.log(e.key)
      // this.selectAll()
    })

    this.element.addEventListener('focusin', (e) => {
      this.selectAll()
    })

    this.element.addEventListener('keyup', (e) => {
      clearTimeout(timeout)
      timeout = setTimeout(() => {
        this.patchTitle()
      }, 150);
    })
  }

  selectAll(){
    const range = document.createRange();
    range.selectNodeContents(this.element);
    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
  }

  async createNextBlock(){
    if ((this.element.innerHTML.length > 0) && (this.blockUrlValue)) {
      const request = new FetchRequest('post', this.blockUrlValue, {
        // body: {},
        responseKind: "turbo-stream"
      })
      const response = await request.perform()
      if (response.ok) {
        // this.element.remove()
        console.log('ok')
        console.log('response.url', response)
        // Turbo.visit(response.response.url, { frame: '_top', turbo: true })
        this.getUrl(response.response.url)
      } else {
        console.log('not ok')
      }
    }
  }

  async getUrl(url) {
    const request = new FetchRequest('get', url, {
      // body: {},
      responseKind: "turbo-stream"
    })
    const response = await request.perform()
    if (response.ok) {
      console.log('ok')
      console.log('response', response)
      // Turbo.visit(response.response.url, { frame: '_top', turbo: true })
    } else {
      console.log('not ok')
    }
  }

  async patchTitle() {
    // console.log(this.nameValue)
    // console.log(this.propValue)
    const name = this.nameValue
    const prop = this.propValue

    const obj = {
      [name]: { [prop]: this.element.innerHTML }
    }
    // console.log(obj)
    // console.log(this.element.innerHTML.length)
    if (this.element.innerHTML.length > 0) {
      const request = new FetchRequest('patch', this.urlValue, {
        body: JSON.stringify(obj),
        responseKind: "turbo-stream"
      })
      const response = await request.perform()
      if (response.ok) {
        // this.element.remove()
      } else {
        console.log('not ok')
      }
    }
  }
}
