import { Application } from "@hotwired/stimulus"
// import Dropdown from '@stimulus-components/dropdown'
import AutoSubmit from '@stimulus-components/auto-submit'
import TextareaAutogrow from 'stimulus-textarea-autogrow'
import ReadMore from '@stimulus-components/read-more'


const application = Application.start()
// application.register('dropdown', Dropdown)
application.register('auto-submit', AutoSubmit)
application.register('textarea-autogrow', TextareaAutogrow)
application.register('read-more', ReadMore)

// Configure Stimulus development experience
application.debug = false
window.Stimulus   = application

import * as ActiveStorage from "@rails/activestorage"
ActiveStorage.start()

export { application }