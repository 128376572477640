// Import and register all your controllers from the importmap via controllers/**/*_controller
import { application } from "./application"
// import { eagerLoadControllersFrom } from "@hotwired/stimulus-loading"
// eagerLoadControllersFrom("controllers", application)

import BigpicController from "./bigpic_controller.js"
application.register("bigpic", BigpicController)

import ChangedController from "./changed_controller.js"
application.register("changed", ChangedController)

import CodeController from "./code_controller.js"
application.register("code", CodeController)

import CompareController from "./compare_controller.js"
application.register("compare", CompareController)

// import CraftController from "./craft_controller.js"
// application.register("craft", CraftController)

import CteditableController from "./cteditable_controller.js"
application.register("cteditable", CteditableController)

import DocumentController from "./document_controller.js"
application.register("document", DocumentController)

import DropdownController from "./dropdown_controller.js"
application.register("dropdown", DropdownController)

import EntrypicController from "./entrypic_controller.js"
application.register("entrypic", EntrypicController)

import controller from "./form_redirect_controller.js"
application.register("form-redirect", controller)

import hello from "./hello_controller.js"
application.register("hello", hello)

import hotkey from "./hotkey_controller.js"
application.register("hotkey", hotkey)

import keepscroll from "./keepscroll_controller.js"
application.register("keepscroll", keepscroll)

import modal from "./modal_controller.js"
application.register("modal", modal)

import outline from "./outline_controller.js"
application.register("outline", outline)

import picsec from "./picsec_controller.js"
application.register("picsec", picsec)

import picture from "./picture_controller.js"
application.register("picture", picture)

import preview from "./preview_controller.js"
application.register("preview", preview)

import scroll from "./scroll_controller.js"
application.register("scroll", scroll)

import section from "./section_controller.js"
application.register("section", section)

import psec from "./psec_controller.js"
application.register("psec", psec)

import snapper from "./snapper_controller.js"
application.register("snapper", snapper)

import sortable from "./sortable_controller.js"
application.register("sortable", sortable)

import sticky from "./sticky_controller.js"
application.register("sticky", sticky)

import table from "./table_controller.js"
application.register("table", table)

import toc from "./toc_controller.js"
application.register("toc", toc)

import uploadzone from "./uploadzone_controller.js"
application.register("uploadzone", uploadzone)

import flow from "./flow_controller.js"
application.register("flow", flow)

import LexicalController from "./lexical_controller.js"
application.register("lexical", LexicalController)


import BlockNoteController from "./blocknote_controller.js"
application.register("blocknote", BlockNoteController)