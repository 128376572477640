import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    section: String
  }

  goto(){
    const domId = `${this.sectionValue}`
    const targetSection = document.getElementById(domId)
    targetSection.scrollIntoView({ behavior: 'smooth' })
  }
}