import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  static values = {
    url: String,
  }

  visit(e) {
    Turbo.visit(this.urlValue, { turbo: true, acceptsStreamResponse: true })
    // Turbo.visit(this.urlValue)
  }
}