// Configure your import map in config/importmap.rb. Read more: https://github.com/rails/importmap-rails
import "@hotwired/turbo-rails"
import "./controllers"

// import "./utils/trix.esm"
import "trix"
import "@rails/actiontext"

// import { createConsumer } from "@rails/actioncable"

// import consumer from "./chanconsumer"

// import {eventToHotkeyString} from '@github/hotkey'
// const GLOBAL_HOTKEY = 'Escape'

// document.addEventListener('keydown', (event) => {
//   let hotkeyString = eventToHotkeyString(event)
//   if (!hotkeyString) return
//   if (hotkeyString !== GLOBAL_HOTKEY) return

//   runGlobalHotkeyCommand()
// })

document.addEventListener("turbo:frame-missing", (event) => {
  console.log('turbo:frame-missing')
  event.preventDefault();
  const { detail: { response, visit } } = event;
  Turbo.visit(response.url, { frame: '_top'});
});

addEventListener("direct-upload:initialize", event => {
  const { target, detail } = event
  const { id, file } = detail
  target.insertAdjacentHTML("beforebegin", `
    <div id="direct-upload-${id}" class="direct-upload direct-upload--pending">
      <div id="direct-upload-progress-${id}" class="direct-upload__progress" style="width: 0%"></div>
      <span class="direct-upload__filename"></span>
    </div>
  `)
  target.previousElementSibling.querySelector(`.direct-upload__filename`).textContent = file.name
})

addEventListener("direct-upload:start", event => {
  const { id } = event.detail
  const element = document.getElementById(`direct-upload-${id}`)
  element.classList.remove("direct-upload--pending")
})

addEventListener("direct-upload:progress", event => {
  const { id, progress } = event.detail
  const progressElement = document.getElementById(`direct-upload-progress-${id}`)
  progressElement.style.width = `${progress}%`
})

addEventListener("direct-upload:error", event => {
  event.preventDefault()
  const { id, error } = event.detail
  const element = document.getElementById(`direct-upload-${id}`)
  element.classList.add("direct-upload--error")
  element.setAttribute("title", error)
})

addEventListener("direct-upload:end", event => {
  const { id } = event.detail
  const element = document.getElementById(`direct-upload-${id}`)
  element.classList.add("direct-upload--complete")
  console.log('direct upload complete')
})
