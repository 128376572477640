import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "fileInput",
    "submitInput",
    'message'
  ]

  static values = {
    accepted: Boolean
  }

  connect() {
    this.element.addEventListener("dragover", this.preventDragDefaults);
    this.element.addEventListener("dragenter", this.preventDragDefaults);
  }

  disconnect() {
    this.element.removeEventListener("dragover", this.preventDragDefaults);
    this.element.removeEventListener("dragenter", this.preventDragDefaults);
  }

  preventDragDefaults(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  trigger() {
    if (this.acceptedValue) {
      console.log('already accepted')
    } else {
      console.log('firing')
      this.fileInputTarget.click();
    }
  }

  acceptFiles(event) {
    this.acceptedValue = true
    event.preventDefault();
    event.stopPropagation();
    const files = event.dataTransfer ? event.dataTransfer.files : event.target.files;
    this.fileInputTarget.files = files
    this.submitInputTarget.click();
  }
}