import { Controller } from "@hotwired/stimulus"
import { patch } from "@rails/request.js"
import consumer from "./../channels/consumer"
import styles from "./../utils/styles"

import cytoscape from 'cytoscape'
import dagre from 'cytoscape-dagre'

cytoscape.use( dagre )
cytoscape.warnings(false)

export default class extends Controller {
  static targets = [ "holder" ]
  static values = {
    items: Array,
    fit: String,
    url: String,
    cyJson: Object,
    shuffle: String,
    eid: String,
    warninigs: Boolean,
    imaging: Boolean,
  }

  static classes = [ "hover" ]

  disconnect(){
    // document.removeEventListener("turbo:load", this.initMap())
    window.cy = null
    const cy = this.holderTarget._cyreg.cy
    localStorage.setItem(`${this.element.id}-cy`, JSON.stringify(cy.json()));
    this.patchState()
  }

  connect() {
    // console.log('pics connected')
    // document.addEventListener("turbo:load", this.initMap())
    this.initMap()
    // console.log()
    localStorage.setItem(`${this.element.id}-cy`, JSON.stringify(this.cyJsonValue));
    this.connectToChannel()
  }

  _connected(){
    console.log('connected mofo')
  }

  _disconnected(){
    console.log('disconnected mofo')
  }

  _received(data) {
    console.log('received entry')
    const cy = this.holderTarget._cyreg.cy
    // console.log('cy', cy)
    const savedJson = cy.json()

    const items = JSON.parse(data.nodes)

    // console.log('items', items)
    // console.log('savedJson', savedJson)

    if (savedJson) {

      const currentElements = savedJson.elements.nodes
      const currentEdges = savedJson.elements.edges

      // console.log('currentElements')

      if (currentElements) {
        const newItems = []
        items.forEach((item) => {
          var result = currentElements.find(obj => {
            return obj.data.id === item.data.id
          })
          if (result) {
            // console.log('item', item.data.parent)
            // console.log('result', result.data.parent)
            const merged = {...result, ...item}
            merged.data = item.data
            // console.log('merged', merged.data.parent)
            newItems.push(merged)
          } else {
            newItems.push(item)
          }
        });

        cy.json({ elements: []})
        cy.json({ elements: newItems})

      }

      else {
        cy.json({ elements: items})
        cy.center()
      }
    }
    this.saveState()
  }

  connectToChannel(){
    const thisRef = this

    const subscId = { channel: "EntryChannel", entry_id: this.eidValue }

    const subscIdStr = JSON.stringify(subscId)
    console.log('subscId', subscIdStr)
    
    let founded = false

    const subscriptionManager = consumer.subscriptions;
    const subscriptions = subscriptionManager.subscriptions;
    const uniqueSubscriptions = [];

    consumer.subscriptions.subscriptions.forEach((element) => {
      console.log('el', element)
      if (element.identifier == subscIdStr) {
        founded = element
      }
    })
    console.log(founded);
    console.log('consumer.subscriptions', consumer.subscriptions.subscriptions)

    if (founded) {
      console.log('founded, forgetting', founded)
      subscriptionManager.forget(founded)
    }

    this.subscription = consumer.subscriptions.create(
      {
        channel: "EntryChannel",
        entry_id: this.eidValue,
      },
      {
        connected: this._connected.bind(this),
        disconnected: this._disconnected.bind(this),
        received: this._received.bind(this),
      }
    );
  }

  initMap() {
    // console.log('hello', this.itemsValue)
    const controller = this
    // const styles = this.styles()

    var cy = window.cy = cytoscape({

      container: controller.holderTarget,
      boxSelectionEnabled: false,
      wheelSensitivity: 0.1,
      layout: {
        name: 'dagre',
        // name: 'klay',
        rankDir: 'TB',
        // columns: 2,
        // rankSep: 20,
        edgeSep: 20,
        avoidOverlap: true,
        nodeDimensionsIncludeLabels: true,
        // edgeSep: 10,
        // nodeDimensionsIncludeLabels: true,
        // animate: true,
        minLen: function( e ){ 
          // console.log('edge', e.data('min_len'))
          return e.data('min_len')
        }
        // edgeWeight: function( edge ){ return 0 }
      },

      style: styles,

      elements: controller.itemsValue,
      ready: (e) => {
        const cy = e.cy
        console.log(`${this.element.id}-cy`)
        const savedJson = JSON.parse(localStorage.getItem(`${this.element.id}-cy`))

        // console.log('ready savedJson', savedJson)
        // console.log('this.itemsValue', this.itemsValue)
        if (savedJson && savedJson.elements) {
          // console.log('yoba')
          const currentElements = savedJson.elements.nodes

          if (currentElements) {
            const newItems = this.itemsValue.map((item) => {
              var result = currentElements.find(obj => {
                return obj.data.id === item.data.id
              })
              if (result) {
                const merged = {...result, ...item}
                const unselected = {...merged, ...{selected: false}}
                return unselected
              } else {
                return {...item, ...{selected: false}}
              }
            });

            const merged = Object.assign(savedJson, { elements: newItems, style: styles } )

            // console.log('merged', merged)
            cy.json({elements: [], style: []})
            cy.json(merged)
          }
        }
        else {
          // console.log('wat tt')
          // this.saveImage(cy, 'ready')
        }
        this.saveState()
      }
    });


    const fitBtn = document.querySelector(`#${this.fitValue}`)

    if (fitBtn) {
      fitBtn.addEventListener('click', (e) => {
        e.preventDefault()
        // console.log('fitting')
        cy.fit()
      })
    }

    // const shuffleBtn = document.querySelector(`#${this.shuffleValue}`)

    // if (shuffleBtn) {
    //   shuffleBtn.addEventListener('click', (e) => {
    //     e.preventDefault()
    //     cy.layout({ name: 'dagre' }).run()
    //     localStorage.removeItem(`${this.element.id}-cy`);
    //     this.saveImage(cy, 'dagre')
    //   })
    // }

    // cy.nodes().renderHTMLNodes()

    // cy.nodes().on('onetap', (e) => {
    //   var clickedNode = e.target;
    //   const data = clickedNode.data()
    //   console.log("yaya", data)
    //   if (data.click != false) {
    //     // const url = "/themes/" + data.theme_id + "/items/" + data.id
    //     // Turbo.visit(data.url)
    //   }
    // });
    

    cy.on('zoom', (e) => {
      console.log('zooming')
      clearTimeout(timeout)
      timeout = setTimeout(() => {
        // console.log('saving image', cy.pan())
        
        // console.log('dragfree')
        this.saveState()
      }, 150);
    })

    cy.on('pan', (e) => {
      console.log('paning')
      clearTimeout(timeout)
      timeout = setTimeout(() => {
        // console.log('saving image', cy.pan())
        
        // console.log('dragfree')
        // this.saveState()
      }, 150);
    })


    let timeout = null;
    cy.on('dragfree', (e) => {
      console.log('dragging')
      clearTimeout(timeout)
      timeout = setTimeout(() => {
        // console.log('saving image', cy.pan())
        
        // console.log('dragfree')
        this.saveState()
      }, 150);
    })

    // cy.on('dragfree', (e) => {
    //   console.log('dragfree')
    //   this.saveState()
    //   this.saveImage(cy)
    // })

    const clickEvent = (event) => {
      var evtTarget = event.target;

      if( evtTarget === cy ){
        // console.log('tap on background');
        // document.querySelector('#nodes').innerHTML = ''
        console.log('raz')
        // Turbo.visit(this.urlValue, { frame: 'nodes' })
        // Turbo.visit(this.urlValue, { turbo: true, acceptsStreamResponse: true })
      } else {
        // console.log('tap on some element');
        const clickedNode = event.target;
        const data = clickedNode.data()

        // console.log('clickedNode', clickedNode)
        // this.saveState()
        // this.saveImage(cy)

        if (data.path) {
          console.log('dva')
          // const bottom = document.querySelector('#nodes').classList.add('h-1/2')
          Turbo.visit(`${data.path}?scroll=yesbaby`, { frame: 'nodes' })
          // console.log('data.path', data.path)
          // Turbo.visit(this.path, { turbo: true, acceptsStreamResponse: true })
        }
      }      
    }

    cy.on('click', clickEvent);
  }

  saveState(){
    // console.log('saveState', `${this.element.id}-cy`)
    const cy = this.holderTarget._cyreg.cy
    // console.log(cy.zoom())
    this.patchState()
    localStorage.setItem(`${this.element.id}-cy`, JSON.stringify(cy.json()));
  }

  async patchState(){
    console.log('patchState')
    const cy = this.holderTarget._cyreg.cy
    const savedJson = cy.json()

    const response = await patch(this.urlValue, {
      responseKind: "turbo-stream",
      body: JSON.stringify({
        entry: {
          cy: savedJson
        }
      })
    });

    if (response.ok) {
      // console.log('saved')
    } else {
      // console.log('error')
    }
  }

  saveImage(cy, from = ''){

  }

  _saveImage(cy, from = ''){
    console.log('saveImage imagingValue', this.imagingValue)
    if (this.imagingValue) {
      console.log('already saving')
    } else {
      this.imagingValue = true
      console.log(`called saveImage from ${from} ${Math.random()}`)
      window.imgtimeout = null;
      clearTimeout(window.imgtimeout)

      window.imgtimeout = setTimeout(() => {
        console.log('saving image', cy.pan())
        this.postImage(cy, 'saveImage')
      }, 350);
    }
  }

  async postImage(cy, from = ''){
    console.log('imagingValue', this.imagingValue)
    if (cy.nodes().length > 0) {
      const pngBlob = await cy.png({
        full: true, bg: '#ffffff', scale: 4,
        output: 'blob-promise',
      });

      let formData = new FormData();
      formData.append("entry[png]", pngBlob);
      
      console.log('firing', this.urlValue)
      const response = await patch(this.urlValue, {
        responseKind: "turbo-stream",
        body: formData
      });


      if (response.ok) {
        console.log('saved')
      } else {
        console.log('error')
      }
      this.imagingValue = false
      console.log('e imagingValue', this.imagingValue)
    }
  }
}
