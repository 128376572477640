import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  connect() {
    var elems = this.element.querySelectorAll("[colwidth]");
    [].forEach.call(elems, function(el) {
      const width = el.getAttribute('colwidth')
      const newWidth = `width: ${width}px !important`;
      el.style = newWidth
    });
  }
}