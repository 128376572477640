import Theme from "./Theme";
import React, { useState, useEffect, useCallback } from 'react';
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
// import TreeViewPlugin from "./plugins/TreeViewPlugin";
import ToolbarPlugin from "./plugins/ToolbarPlugin";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { ListItemNode, ListNode } from "@lexical/list";
import { CodeHighlightNode, CodeNode } from "@lexical/code";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { MarkdownShortcutPlugin } from "@lexical/react/LexicalMarkdownShortcutPlugin";
import { TRANSFORMERS } from "@lexical/markdown";
import { createEditor } from 'lexical';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import {TablePlugin} from '@lexical/react/LexicalTablePlugin';

import { $generateNodesFromDOM, $generateHtmlFromNodes } from '@lexical/html';

import ListMaxIndentLevelPlugin from "./plugins/ListMaxIndentLevelPlugin";
import CodeHighlightPlugin from "./plugins/CodeHighlightPlugin";
import AutoLinkPlugin from "./plugins/AutoLinkPlugin";

function Placeholder() {
  return <div className="editor-placeholder">Enter some rich text...</div>;
}

const editorConfig = {
  // The editor theme
  theme: Theme,
  // Handling of errors during update
  onError(error) {
    throw error;
  },
  // Any custom nodes go here
  nodes: [
    HeadingNode,
    ListNode,
    ListItemNode,
    QuoteNode,
    CodeNode,
    CodeHighlightNode,
    TableNode,
    TableCellNode,
    TableRowNode,
    AutoLinkNode,
    LinkNode
  ]
};


const getEditorStateFromJSON = (json) => {
  const editor = createEditor(editorConfig);
  const parsedState = editor.parseEditorState(json);
  return parsedState;
}

export default function Editor({ lexicalController, initialContent }) {

  const [editorState, setEditorState] = useState(null);
  const [config, setConfig] = useState(null);

  const handleOnChange = useCallback(
    (state, editor) => {
      editor.update(() => {
        // Get the latest content
        const lexicalJSON = JSON.stringify(state.toJSON());
        const html = $generateHtmlFromNodes(editor, null);

        // Update the form fields through the controller
        lexicalController.setBodyValue(html);
        lexicalController.setBodyJsonValue(lexicalJSON);

        // Submit the form (optional, you might want to do this on a save button click)
        lexicalController.submitForm();
      });
    },
    [lexicalController]
  );

  useEffect(() => {
    if (initialContent) {
      const editor = createEditor(editorConfig);
      const parsedState = editor.parseEditorState(initialContent);
      setConfig({
        ...editorConfig,
        editorState: parsedState,
      });
    } else {
      setConfig({
        ...editorConfig,
        editorState: null,
      });
    }
  }, [initialContent]);

  if (!config) {
    return <div>Грузим...</div>; // Or any other loading indicator
  }

  return (
    <LexicalComposer initialConfig={config}>
      <div className="editor-container">
        <ToolbarPlugin />
        <div className="editor-inner">
          <RichTextPlugin
            contentEditable={<ContentEditable className="editor-input" />}
            placeholder={<Placeholder />}
            ErrorBoundary={LexicalErrorBoundary}
          />
          <HistoryPlugin />
          <AutoFocusPlugin />
          <CodeHighlightPlugin />
          <ListPlugin />
          <LinkPlugin />
          <TablePlugin />
          <AutoLinkPlugin />
          <ListMaxIndentLevelPlugin maxDepth={7} />
          <OnChangePlugin onChange={handleOnChange} />
        </div>
      </div>
    </LexicalComposer>
  );
}
