import { Controller } from "@hotwired/stimulus"
import { EditorView, basicSetup } from "codemirror"
import { html } from "@codemirror/lang-html"
import { css } from "@codemirror/lang-css"
export default class extends Controller {
  static targets = [
    "input", "code"
  ];

  static values = {
    language: String
  }

  connect() {
    let extLang
    if (this.languageValue == 'html')
      extLang = html()
    else if (this.languageValue == 'css')
      extLang = css()
    let myView = new EditorView({
      doc: this.inputTarget.value,
      extensions: [
        basicSetup,
        extLang,
        EditorView.lineWrapping,
        EditorView.updateListener.of((e) => {
          this.inputTarget.value = e.state.doc.toString();
        })
      ],
      parent: this.codeTarget
    })
  }
}
