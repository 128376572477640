import { Controller } from "@hotwired/stimulus"
import { patch } from "@rails/request.js"
import consumer from "./../channels/consumer"

import styles from "./../utils/styles"

import cytoscape from 'cytoscape'
import dagre from 'cytoscape-dagre'

cytoscape.use( dagre )
cytoscape.warnings(false)

export default class extends Controller {
  static targets = [ "holder" ]
  static values = {
    items: Array,
    fit: String,
    url: String,
    cyJson: Object,
    shuffle: String,
    sid: String,
    warninigs: Boolean,
    nav: String,
    sediting: Boolean
  }
  static classes = [ "hover" ]

  disconnect() {
    window.cy = null
    document.removeEventListener("turbo:load", this.initMap())
  }

  connect() {
    document.addEventListener("turbo:load", this.initMap())
    localStorage.setItem(`${this.element.id}-cy`, JSON.stringify(this.cyJsonValue));
    this.connectToChannel()
  }

  _connected(){
    console.log('connected mofo')
  }

  _disconnected(){
    console.log('disconnected mofo')
  }

  _received(data) {
    console.log('received')
    const cy = this.holderTarget._cyreg.cy
    // console.log('cy', cy)
    const savedJson = cy.json()

    const items = JSON.parse(data.nodes)

    // console.log('items', items)
    // console.log('savedJson', savedJson)

    if (savedJson) {

      const currentElements = savedJson.elements.nodes
      const currentEdges = savedJson.elements.edges

      // console.log('currentElements')

      if (currentElements) {
        const newItems = []
        items.forEach((item) => {
          var result = currentElements.find(obj => {
            return obj.data.id === item.data.id
          })
          if (result) {
            // console.log('item', item.data.parent)
            // console.log('result', result.data.parent)
            const merged = {...result, ...item}
            merged.data = item.data
            // console.log('merged', merged.data.parent)
            newItems.push(merged)
          } else {
            newItems.push(item)
          }
        });

        cy.json({ elements: []})
        cy.json({ elements: newItems})

      }

      else {
        cy.json({ elements: items})
        cy.center()
      }
    }
    // this.saveImage(cy, 'received')
    this.saveState()
  }

  connectToChannel(){
    const thisRef = this

    const subscId = { channel: "SectionChannel", section_id: this.sidValue }

    const subscIdStr = JSON.stringify(subscId)
    console.log('subscId', subscIdStr)
    
    let founded = false

    const subscriptionManager = consumer.subscriptions;
    const subscriptions = subscriptionManager.subscriptions;
    const uniqueSubscriptions = [];

    consumer.subscriptions.subscriptions.forEach((element) => {
      // console.log('el', element)
      if (element.identifier == subscIdStr) {
        founded = element
      }
    })
    console.log(founded);
    // console.log('consumer.subscriptions', consumer.subscriptions.subscriptions)

    if (founded) {
      // console.log('founded, forgetting', founded)
      subscriptionManager.forget(founded)
    }

    this.subscription = consumer.subscriptions.create(
      {
        channel: "SectionChannel",
        section_id: this.sidValue,
      },
      {
        connected: this._connected.bind(this),
        disconnected: this._disconnected.bind(this),
        received: this._received.bind(this),
      }
    );
  }


  initMap() {
    // console.log('hello', this.itemsValue)
    const controller = this

    // const styles = this.styles()

    var cy = window.cy = cytoscape({

      container: controller.holderTarget,
      boxSelectionEnabled: false,
      wheelSensitivity: 0.1,
      layout: {
        name: 'dagre',
        // name: 'klay',
        rankDir: 'TB',
        // columns: 2,
        // rankSep: 20,
        edgeSep: 20,
        avoidOverlap: true,
        nodeDimensionsIncludeLabels: true,
        // edgeSep: 10,
        // nodeDimensionsIncludeLabels: true,
        // animate: true,
        minLen: function( e ){ 
          // console.log('edge', e.data('min_len'))
          return e.data('min_len')
        }
        // edgeWeight: function( edge ){ return 0 }
      },

      style: styles,

      elements: controller.itemsValue,
      ready: (e) => {
        const cy = e.cy
        console.log(`${this.element.id}-cy`)
        const savedJson = this.cyJsonValue

        console.log('ready cyJson', savedJson)
        console.log('this.itemsValue', this.itemsValue)
        if (savedJson && savedJson.elements) {
          console.log('yoba')
          const currentElements = savedJson.elements.nodes

          if (currentElements) {
            const newItems = this.itemsValue.map((item) => {
              var result = currentElements.find(obj => {
                return obj.data.id === item.data.id
              })
              if (result) {
                const merged = {...result, ...item}
                const unselected = {...merged, ...{selected: false}}
                return unselected
              } else {
                return {...item, ...{selected: false}}
              }
            });

            const merged = Object.assign(savedJson, { elements: newItems, style: styles } )

            console.log('from ready', newItems)
            cy.json({elements: [], styles: []})
            cy.json(merged)
          }
          
          this.saveState()
        }
        else {
          console.log('wat tt')
          // this.saveImage(cy, 'ready')
        }

        console.log('this.navValue', this.navValue)
        if (this.navValue != 'internal') {
          cy.fit()
        }
      }
    });
    
    const fitElement = document.querySelector(`#${this.fitValue}`)
    if (fitElement) {
      document.querySelector(`#${this.fitValue}`).addEventListener('click', (e) => {
        e.preventDefault()
        // console.log('fitting')
        cy.fit()
      })
    }
    const shuffleElement = document.querySelector(`#${this.shuffleValue}`)
    if (shuffleElement) {
      shuffleElement.addEventListener('click', (e) => {
        e.preventDefault()
        cy.layout({ name: 'dagre' }).run()
        localStorage.removeItem(`${this.element.id}-cy`);
        this.saveImage(cy, 'dagre')
      })

    }
    // cy.nodes().renderHTMLNodes()

    // cy.nodes().on('onetap', (e) => {
    //   var clickedNode = e.target;
    //   const data = clickedNode.data()
    //   console.log("yaya", data)
    //   if (data.click != false) {
    //     // const url = "/themes/" + data.theme_id + "/items/" + data.id
    //     // Turbo.visit(data.url)
    //   }
    // });

    // cy.on('zoom', (e) => {
    //   this.saveState('zoom')
    //   // this.saveImage(cy)
    // })

    // cy.on('pan', (e) => {
    //   this.saveState()
    // })

    if (this.navValue == 'internal') {
      console.log('nav internal wtfZZZ')


      let zoomtimeout = null;
      cy.on('zoom', (e) => {
        console.log('zoomzz')
        clearTimeout(zoomtimeout)
        zoomtimeout = setTimeout(() => {
          this.saveState('zoom')
        }, 150);
      })


      let pantimeout = null;
      cy.on('pan', (e) => {
        console.log('pan')
        clearTimeout(pantimeout)
        pantimeout = setTimeout(() => {
          this.saveState('pan')
        }, 150);
      })


      let dragfreetimeout = null;
      cy.on('dragfree', (e) => {
        console.log('dragging')
        clearTimeout(dragfreetimeout)
        dragfreetimeout = setTimeout(() => {
          // console.log('saving image', cy.pan())
          
          // console.log('dragfree')
          this.saveState()
          this.saveImage(cy)
        }, 150);
      })

      // cy.on('dragfree', (e) => {
      //   console.log('dragfree')
      //   this.saveState()
      //   this.saveImage(cy)
      // })

      const clickEvent = (event) => {
        var evtTarget = event.target;

        if( evtTarget === cy ){
          console.log('tap on background');
          // document.querySelector('#nodes').innerHTML = ''
          // Turbo.visit(this.urlValue, { frame: 'nodes' })
          // Turbo.visit(this.urlValue)
          // Turbo.visit(this.path, { turbo: true })
          Turbo.visit(`${this.urlValue}/edit?nodes=bt`, { turbo: true, acceptsStreamResponse: true })
          // Turbo.visit(this.urlValue, { frame: 'nodes' })
        } else {
          console.log('tap on some element');
          const clickedNode = event.target;
          const data = clickedNode.data()

          // console.log('clickedNode', clickedNode)
          // this.saveState()
          // this.saveImage(cy)

          if (data.path) {
            // const bottom = document.querySelector('#nodes').classList.add('h-1/2')
            Turbo.visit(data.path, { frame: 'nodes' })
            // console.log('data.path', data.path)
            // Turbo.visit(this.path, { turbo: true, acceptsStreamResponse: true })
          }
        }      
      }
      if (this.navValue == 'internal') {
        cy.on('click', clickEvent);  
      } else {
        console.log('captured')
      }
    }
  }

  saveState(from = ''){
    // console.log('saveState', `${this.element.id}-cy`)
    // const cy = this.holderTarget._cyreg.cy
    // console.log(cy.zoom())
    this.patchState(from)
    // localStorage.setItem(`${this.element.id}-cy`, JSON.stringify(cy.json()));
  }

  // disconnect(){
  //   // const cy = this.holderTarget._cyreg.cy
  //   // localStorage.setItem(`${this.element.id}-cy`, JSON.stringify(cy.json()));
  //   // this.patchState()
  // }

  async patchState( from ){
    console.log('patchState from', from)
    const cy = this.holderTarget._cyreg.cy
    const savedJson = cy.json()

    const response = await patch(this.urlValue, {
      responseKind: "turbo-stream",
      body: JSON.stringify({
        section: {
          cy: savedJson
        }
      })
    });

    if (response.ok) {
      // console.log('saved')
    } else {
      // console.log('error')
    }
  }

  saveImage(cy, from = ''){

  }

  async _saveImage(cy, from = ''){
    console.log('saveImage from', from)
    if (cy.nodes().length > 0) {

      // const elements = cy.json().elements

      // const newItems = elements.nodes.map((item) => {
      //   return {...item, ...{selected: false}}
      // });

      // if (elements.edges) {
      //   elements.edges.forEach((item) => {
      //     newItems.push(item)
      //   });
      // }

      // // console.log('from image', newItems)
      // cy.json({ elements: [] })
      // cy.json({ elements: newItems })

      // var png64 = cy.png( { full: true, bg: '#ffffff', scale: 2, output: 'base64' })

      const pngBlob = await cy.png({
        full: true, bg: '#ffffff', scale: 4,
        output: 'blob-promise',
      });

      // const fileName = 'myfile.png';
      // const downloadLink = document.createElement('a');
      // downloadLink.href = URL.createObjectURL(pngBlob);
      // downloadLink.download = fileName;
      // downloadLink.click();

      // put the png data in an img tag
      // document.querySelector('#png-eg').setAttribute('src', png64)
      let formData = new FormData();
      formData.append("section[png]", pngBlob);
  
      const response = await patch(this.urlValue, {
        responseKind: "turbo-stream",
        body: formData
      });

      // // put the png data in an img tag
      // // document.querySelector('#png-eg').setAttribute('src', png64)
      
      // const response = await patch(this.urlValue, {
      //   responseKind: "turbo-stream",
      //   body: JSON.stringify({
      //     picture: {
      //       png: png64
      //     } 
      //   })
      // });

      if (response.ok) {
        console.log('saved picsec')
      } else {
        console.log('error picsec')
      }
    }
  }
}
