import { Controller } from "@hotwired/stimulus";
import React from 'react';
import { createRoot } from 'react-dom/client';
import BlockNoteEditor from '../components/BlockNoteEditor';

export default class extends Controller {
  static targets = [ "editor", "body", "bodyJson", "form" ]

  static values = {
    content: Array
  }

  connect() {

    console.log('this.contentValue', this.contentValue)
    
    this.timeout = null;
    this.content = this.data.get("content");
    const root = createRoot(this.editorTarget);
    this.form = this.element;

    root.render(
      <BlockNoteEditor
        content={this.contentValue}
        onUpdate={(json, html) => {
          // console.log("onChange called with:", { json, html }); // Проверяем, что onChange вызывается
          this.bodyTarget.value = html;
          this.bodyJsonTarget.value = json;
          this.scheduleUpdate();
        }}
      />
    );
  }

  scheduleUpdate() {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      // console.log("Submitting form with:", { body: this.bodyTarget.value, body_json: this.bodyJsonTarget.value });
      this.formTarget.requestSubmit();
    }, 300);
  }
}