import { Controller } from "@hotwired/stimulus"
import Sortable from 'sortablejs'
import { post } from "@rails/request.js"

export default class extends Controller {

  static values = {
    sortUrl: String
  }

  connect() {
    var sortable = Sortable.create(this.element, {
      animation: 150,
      handle: ".handle",
      onSort: (evt) => {
        const serialized = sortable.toArray()
        console.log('serialized', serialized)
        this.postData(serialized)
      },
    });
  }

  async postData(serialized){
    const response = await post(this.sortUrlValue, {
      responseKind: "turbo-stream",
      body: JSON.stringify(serialized)
    });
    if (response.ok) {
      // console.log('ok')
    } else {
      // console.log('error')
    }
  }
}