import { Controller } from "@hotwired/stimulus";
import { FetchRequest } from "@rails/request.js"

export default class extends Controller {
  // static targets = ['editor', 'form']

  static values = {
    url: String,
    sectionDomId: String
  }

  connect() {
    
  }

  open(e) {
    e.preventDefault()
    var elems = document.querySelectorAll(".section");
    [].forEach.call(elems, function(el) {
      el.classList.remove("highlight");
      el.classList.remove("highlighted");
      el.classList.remove("scroll-ref");
    });
    var elems = document.querySelectorAll(".web-section");
    [].forEach.call(elems, function(el) {
      el.classList.remove("highlight");
      el.classList.remove("highlighted");
      el.classList.remove("scroll-ref");
    });
    const domId = `${this.sectionDomIdValue}`
    const targetSection = document.getElementById(domId)

    targetSection.classList.remove('scroll-ref')
    targetSection.classList.remove('highlight')
    targetSection.classList.remove('highlighted')

    targetSection.classList.add('scroll-ref')
    targetSection.classList.add('highlight')
    targetSection.classList.add('highlighted')

    targetSection.scrollIntoView( { behavior: 'smooth' } )

    var outlines = document.querySelectorAll(".outline-item");
    [].forEach.call(outlines, function(el) {
      el.classList.remove("current");
    });

    this.element.classList.add('current')
    this.element.classList.add('scroll-ref')
    // Turbo.visit(`${this.urlValue}/edit`, { turbo: true, acceptsStreamResponse: true })
  }
}