import { Controller } from "@hotwired/stimulus"
import { FetchRequest  } from "@rails/request.js"

export default class extends Controller {

  static values = {
    selecting: Boolean,
    selected: String,
    focused: String,
    ctrl: Boolean,
    marker: String,
    marked: Boolean,
    craftable: Boolean,
    shift: Boolean,
    pending: Boolean
  }

  connect() {
    console.log('hello table')
    this.element.addEventListener("turbo:submit-end", (e) => {
      console.log('reloading')
      this.element.closest("turbo-frame").reload();
    });
    this.element.addEventListener('mousedown', (e) => {
      this.mouseDown(e)
    })

    this.element.addEventListener('mouseup', (e) => {
      this.mouseUp(e)
    })

    this.element.addEventListener('mouseover', (e) => {
      this.mouseOver(e)
    })

    this.element.addEventListener('keydown', (e) => {
      if (e.keyCode == 17) {
        this.ctrlValue = true
        console.log('ctrled')
      } else if (e.keyCode == 16) {
        this.shiftValue = true
        console.log('shifted')
      }
    })

    this.element.addEventListener('keyup', (e) => {
      if (e.keyCode == 17) {
        this.ctrlValue = false
        console.log('unctrled')
      } else if (e.keyCode == 16) {
        this.shiftValue = false
        console.log('unshifted')
      }
    })

    console.log('this.focusedValue', this.focusedValue)
    if (this.focusedValue) {
      const target = this.element.querySelector(`[data-id="${this.focusedValue}"]`)
      console.log('selected target', target)
      target.classList.add('selected')
      // target.classList.add('editing')
      target.setAttribute('tabindex', 0)
      target.setAttribute('contenteditable', true)
      // target.setAttribute('contenteditable', true)
      target.addEventListener('keydown', (e) => {
        this.editableReady(e, target)
      })

      const range = document.createRange();
      range.selectNodeContents(target);
      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
      target.focus()
    }
  }

  async insert(url) {
    const request = new FetchRequest('get', url, {
      responseKind: "turbo-stream"
      // responseKind: "html"
    })
    const response = await request.perform()
    if (response.ok) {
      // this.element.remove()
    } else {
      console.log('not ok')
    }
  }


  editableReady(e, target) {
    console.log('editableReady')

    if (this.ctrlValue) {
      if (e.keyCode == 13) {
        // const insertUrl = `${target.dataset.url}/insert/row`
        // console.log('inseritng row after', insertUrl)
        // this.insert(insertUrl)
      }
    } else if (this.shiftValue) {
      if (e.keyCode == 13) {
        // e.preventDefault()
        // const insertUrl = `${target.dataset.url}/insert/col`
        // console.log('inseritng col after', insertUrl)
        // this.insert(insertUrl)
      }
    } else {
      // if (e.keyCode == 13) {
      //   const tr = target.parentNode
      //   const tbody = tr.parentNode
      //   const lastTr = tbody.lastChild
      //   if (lastTr == tr) {
      //     // e.preventDefault()
      //     console.log('inseritng row coz last TR', target.dataset.id)
      //   }
      // } else {
      // }
      let timeout = null;
      target.addEventListener('keyup', (e) => {
        this.pendingValue = true
        clearTimeout(timeout)
        timeout = null
        timeout = setTimeout(() => {
          console.log('patching dataset', target.dataset)
          console.log('patching innerHTML', target.innerHTML)
          this.patchCell(target.dataset.name, target.dataset.prop, target.dataset.url, target.innerHTML)
        }, 250);
      })
      target.addEventListener('keydown', (e) => {
        clearTimeout(timeout)
        timeout = null
      })
    }
  }

  getTarget() {
    const selector = `[data-id="${this.selectedValue}"]`
    const inTD = e.target.closest(`td`)
    const inTH = e.target.closest(`th`)
    let target
    if (e.target.nodeName == 'TD' || e.target.nodeName == 'TH') {
      target = e.target
    } else {
      if (inTD) {
        target = inTD
      } else if (inTH) {
        target = inTH
      }
    }
    return target
  }

  async delete(url) {
    const request = new FetchRequest('delete', url, {
      responseKind: "turbo-stream"
      // responseKind: "html"
    })
    const response = await request.perform()
    if (response.ok) {
      // this.element.remove()
    } else {
      console.log('not ok')
    }
  }

  removeRow(e){
    const selector = `[data-id="${this.selectedValue}"]`
    const target = document.querySelector(selector)
    console.log('target', target.dataset.url)
    const deleteUrl = `${target.dataset.url}/remove/row`
    console.log('remove row after', deleteUrl)
    this.delete(deleteUrl)
  }

  removeCol(e){
    const selector = `[data-id="${this.selectedValue}"]`
    const target = document.querySelector(selector)
    console.log('target', target.dataset.url)
    const deleteUrl = `${target.dataset.url}/remove/col`
    console.log('remove col after', deleteUrl)
    this.delete(deleteUrl)
  }

  addRow(e){
    const selector = `[data-id="${this.selectedValue}"]`
    const target = document.querySelector(selector)
    console.log('target', target.dataset.url)
    const insertUrl = `${target.dataset.url}/insert/row`
    console.log('inseritng row after', insertUrl)
    this.insert(insertUrl)
  }

  addCol(e){
    const selector = `[data-id="${this.selectedValue}"]`
    const target = document.querySelector(selector)
    console.log('target', target.dataset.url)
    const insertUrl = `${target.dataset.url}/insert/col`
    console.log('inseritng col after', insertUrl)
    this.insert(insertUrl)
  }

  mouseDown(e){
    // console.log('mouseDown', e.target)
    this.selectingValue = true
    const selector = `[data-id="${this.selectedValue}"]`
    const inTD = e.target.closest(`td`)
    const inTH = e.target.closest(`th`)
    // console.log('selector', selector)
    // console.log('inTD', inTD)
    // console.log('inTH', inTH)

    let target

    if (e.target.nodeName == 'TD' || e.target.nodeName == 'TH') {
      target = e.target
    } else {
      if (inTD) {
        target = inTD
      } else if (inTH) {
        target = inTH
      }
    }

    // console.log('and target', target)

    if (target) {
      if (this.selectedValue == target.dataset.id) {
        //  || 
        console.log('skip')

      } else {
        this.selectedValue = target.dataset.id
        var elems = this.element.querySelectorAll(".selected");
        [].forEach.call(elems, (el) => {
          el.classList.remove("selected");
          // el.classList.remove("editing");
          el.setAttribute('contenteditable', false)
          el.removeAttribute('tabindex')
          el.removeEventListener('keydown', (e) => {
            console.log('remove')
            this.editableReady(e, target)
          })
          el.removeEventListener('contextmenu', (e) => {
            this.showContextMenu(e, target)
          })
        });
        target.classList.add('selected')
        // target.classList.add('editing')
        target.setAttribute('tabindex', 0)
        target.setAttribute('contenteditable', true)
        // target.setAttribute('contenteditable', true)
        target.addEventListener('keydown', (e) => {
          this.editableReady(e, target)
        })
        this.hideMenu()
        target.addEventListener('contextmenu', (e) => {
          this.showContextMenu(e, target)
        })
      }
    }
  }

  mouseUp(e){
    // console.log('mouseUp', e.target)
    this.selectingValue = false
    var elems = this.element.querySelectorAll(".bg-blue-50");
    [].forEach.call(elems, function(el) {
      el.classList.remove("bg-blue-50");
    });
  }

  hideMenu(){
    const menu = document.querySelector(`#${this.markerValue}`)
    menu.classList.add('invisible')
    const table = document.querySelector(`#${this.tableMenuValue}`)
    menu.classList.add('invisible')
  }

  showContextMenu(event, target) {
    if (target.classList.contains('marker')) {
      console.log('marker click')
    } else {
      event.preventDefault()
      const menu = document.querySelector(`#${this.markerValue}`)
      menu.classList.remove('invisible')

      const addMarker = menu.querySelector(`.add-marker`)

      if (addMarker) {
        if (this.craftableValue && target.dataset.name == 'cell' && target.dataset.marked == 'no') {
          addMarker.classList.remove('hidden')
        } else {
          addMarker.classList.add('hidden')
        }
      }

      const removeRow = menu.querySelector(`.remove-row`)
      if (target.dataset.name == 'col') {
        removeRow.classList.add('hidden')
      } else {
        removeRow.classList.remove('hidden')
      }

      let x = event.layerX
      let y = event.layerY + 10
      menu.style.left = x + "px";
      menu.style.top = y + "px";
      menu.style.display = "block";
    }
  }

  async attachMarker() {
    const selector = `[data-id="${this.selectedValue}"]`
    const target = document.querySelector(selector)
    console.log('target', target.dataset.url)

    const url = `${target.dataset.url}/mark`
    const request = new FetchRequest('get', url, {
      responseKind: "turbo-stream"
    });
    const response = await request.perform()
    if (response.ok) {

    } else {

    }
  }

  mouseOver(e){
    // console.log('mouseOver', e.target)
    // if (this.selectingValue) {
    //   e.target.classList.add('bg-blue-50')
    // }
  }

  async patchCell(name, prop, url, value) {
    if (this.pendingValue == true) {
      this.pendingValue = false
      const obj = {
        [name]: { [prop]: value }
      }
      if (this.element.innerHTML.length > 0) {
        console.log('patch to', url)
        const request = new FetchRequest('patch', url, {
          body: JSON.stringify(obj),
          responseKind: "turbo-stream"
        })
        const response = await request.perform()
        if (response.ok) {
          // this.element.remove()
        } else {
          console.log('not ok')
        }
      }
    }
  }
}
