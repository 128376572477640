import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    section: String
  }

  highlight(e) {
    e.preventDefault()
    var elems = document.querySelectorAll(".changed-section");
    [].forEach.call(elems, function(el) {
      el.classList.remove("highlight");
      el.classList.remove("highlighted");
      el.classList.remove("scroll-ref");
    });
    console.log(this.sectionValue)
    const targetSection = document.getElementById(this.sectionValue)
    targetSection.classList.add('scroll-ref')
    targetSection.classList.add('highlight')
    targetSection.classList.add('highlighted')
    targetSection.scrollIntoView( { behavior: 'smooth' } )
  }
}