import Dagre from '@dagrejs/dagre';
import React, { useCallback, useState } from 'react';
import {
  Background,
  ReactFlow,
  ReactFlowProvider,
  Panel,
  useNodesState,
  useEdgesState,
  addEdge,
  MiniMap,
  Controls,
  useReactFlow
} from '@xyflow/react';
 
// import '@xyflow/react/dist/base.css';
 
// import './tailwind-config.js';
import CustomNode from './custom_node';
 
const nodeTypes = {
  custom: CustomNode,
};
 
const getLayoutedElements = (nodes, edges, options) => {
  const g = new Dagre.graphlib.Graph().setDefaultEdgeLabel(() => ({}));
  g.setGraph({ rankdir: options.direction });
 
  edges.forEach((edge) => g.setEdge(edge.source, edge.target));
  nodes.forEach((node) =>
    g.setNode(node.id, {
      ...node,
      width: node.measured?.width ?? 0,
      height: node.measured?.height ?? 0,
    }),
  );
 
  Dagre.layout(g);
 
  return {
    nodes: nodes.map((node) => {
      const position = g.node(node.id);
      // We are shifting the dagre node position (anchor=center center) to the top left
      // so it matches the React Flow node anchor point (top left).
      const x = position.x - (node.measured?.width ?? 0) / 2;
      const y = position.y - (node.measured?.height ?? 0) / 2;
 
      return { ...node, position: { x, y } };
    }),
    edges,
  };
};

 
const LayoutFlow = ({ items, iedges }) => {
  const { fitView } = useReactFlow();
  const [nodes, setNodes, onNodesChange] = useNodesState(items);
  const [edges, setEdges, onEdgesChange] = useEdgesState(iedges);
  const [selectedNodeId, setSelectedNodeId] = useState(null);

  const onConnect = useCallback(
    (params) => setEdges((eds) => addEdge(params, eds)),
    [],
  );

  const onLayout = useCallback(
    (direction) => {
      console.log(nodes);
      const layouted = getLayoutedElements(nodes, edges, { direction });
 
      setNodes([...layouted.nodes]);
      setEdges([...layouted.edges]);
 
      window.requestAnimationFrame(() => {
        fitView();
      });
    },
    [nodes, edges],
  );

  const onNodeClick = useCallback((event, node) => {
    console.log('click', node);
    setSelectedNodeId(node.id);
  }, []);

  const onPaneClick = useCallback(() => {
    setSelectedNodeId(null);
  }, []);

  const onNodeDragStart = useCallback((event, node) => {
    console.log('drag start', node);
    // setSelectedNodeId(null); // Сбрасываем выбранную ноду при начале перетаскивания
  }, []);

  const onNodeDragStop = useCallback((event, node) => {
    console.log('drag stop', node);
  }, []);

  const updatedNodes = nodes.map((node) => {
    // console.log('node here', node.id, selectedNodeId)
    if (node.id === selectedNodeId) {
      // Меняем borderColor и borderWidth на outline
      node.style = {
        ...node.style,
        outline: '2px solid red', // Добавляем outline
        border: 'none', // Убираем border
      };
    } else {
      // Сбрасываем outline для остальных нод
      node.style = { ...node.style, outline: '' };
    }
    return node;
  });

  // console.log('items', items)
  // console.log('iedges', iedges)
 
  return (
    <ReactFlow
      nodes={updatedNodes}
      edges={edges}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      onNodeClick={onNodeClick}
      onPaneClick={onPaneClick}
      onConnect={onConnect}
      nodeTypes={nodeTypes}
      onNodeDragStart={onNodeDragStart}
      onNodeDragStop={onNodeDragStop}
      fitView
      className=""
    >
      <Panel position="top-right">
        <button onClick={() => onLayout('TB')}>vertical layout</button>
        <button onClick={() => onLayout('LR')}>horizontal layout</button>
      </Panel>
      <MiniMap />
      <Background />
    </ReactFlow>
  );
};

// export default App;

 
export default function (props) {
  return (
    <ReactFlowProvider>
      <LayoutFlow {...props}/>
    </ReactFlowProvider>
  );
}