import { Controller } from "@hotwired/stimulus"
import { createRoot, hydrateRoot } from 'react-dom/client';
import Editor from '../components/LexicalEditor';
import React from 'react';

// Connects to data-controller="lexical"
export default class extends Controller {
  static targets = [ "editor", "form", "body", "bodyJson" ];
  static values = {
    initialContent: { type: Object, default: {}}
  }

  connect() {
    // Create a React root and render the editor
    this.timeout = null;
    const reactRoot = createRoot(this.editorTarget);
    const initialContent = this.initialContentValue;
    console.log('initialContent', initialContent)

    reactRoot.render(<Editor lexicalController={this} initialContent={initialContent} />);
  }

  setBodyValue(html) {
    // console.log('setBodyValue', html)
    this.bodyTarget.value = html;
  }

  setBodyJsonValue(json) {
    // console.log('setBodyJsonValue', json)
    this.bodyJsonTarget.value = json;
  }

  submitForm() {
    // console.log('requestSubmit')
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      // console.log("Submitting form with:", { body: this.bodyTarget.value, body_json: this.bodyJsonTarget.value });
      this.formTarget.requestSubmit();
    }, 300);
  }
}