import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    var elems = document.querySelectorAll(".section");
    [].forEach.call(elems, function(el) {
      el.classList.remove("highlight");
      el.classList.remove("highlighted");
    });
    var elems = document.querySelectorAll(".section-preview");
    [].forEach.call(elems, function(el) {
      // el.classList.remove("focused");
    });
    // this.element.classList.add('focused')
  }
}
