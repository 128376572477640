// import { useCreateBlockNote, blocksToHTML } from "@blocknote/core"; // Импортируем blocksToHTML
// import { FormattingToolbar, useCreateBlockNote } from "@blocknote/react";
import {
  BlockNoteSchema,
  combineByGroup,
  filterSuggestionItems,
  locales,
} from "@blocknote/core";

import {
  FormattingToolbar,
  SuggestionMenuController,
  getDefaultReactSlashMenuItems,
  useCreateBlockNote,
} from "@blocknote/react";

import { BlockNoteView } from "@blocknote/mantine";
// import { ServerBlockNoteEditor } from "@blocknote/server-util";
// import "@blocknote/core/fonts/inter.css";
// import "@blocknote/mantine/style.css";
import React, {
  useEffect,
  useMemo
} from "react";

import { post } from "@rails/request.js"
import {
  getMultiColumnSlashMenuItems,
  multiColumnDropCursor,
  locales as multiColumnLocales,
  withMultiColumn,
} from "@blocknote/xl-multi-column";

import { DirectUpload } from "@rails/activestorage";


async function uploadFile(file) {
  const body = new FormData();
  body.append("file", file);
 
  const ret = await fetch("https://tmpfiles.org/api/v1/upload", {
    method: "POST",
    body: body,
  });

  return (await ret.json()).data.url.replace(
    "tmpfiles.org/",
    "tmpfiles.org/dl/"
  );
}

function BlockNoteEditor({ onUpdate, content }) {

  console.log('content', content) 

  const passContent = content && (content.length > 0) ? content : undefined

  const editor = useCreateBlockNote({
    dictionary: locales.ru,
    initialContent: passContent,
    uploadFile: async (file) => {
      return new Promise((resolve, reject) => {
        const upload = new DirectUpload(file, '/rails/active_storage/direct_uploads');

        upload.create((error, blob) => {
          if (error) {
            console.error('Upload failed:', error);
            reject(error);
          } else {
            console.log('blob', blob)

            const url = `/b/${blob.signed_id}`
            console.log('blob.url', url)
            resolve(url);
          }
        });
      })
    },
  });

  const onChange = async () => {
    // Converts the editor's contents from Block objects to HTML and store to state.
    // const html = await editor.blocksToHTMLLossy(editor.document);
    // setHTML(html);

    const json = JSON.stringify(editor.topLevelBlocks, null, 2);
    // const json = editor.topLevelBlocks
    // Используем blocksToHTML из @blocknote/core


    // const renderer = ServerBlockNoteEditor.create();
    // const html = await renderer.blocksToFullHTML(editor.document);
    const html = await editor.blocksToHTMLLossy(editor.document);
    // const html = await editor.blocksToFullHTML(editor.document);
    onUpdate(json, html);
  };

  // console.log('editor.document', editor.document)
  // // Устанавливаем фокус на первый блок после инициализации
  // useEffect(() => {
  //   const firstBlock = editor.document[0]; // Получаем первый блок
  //   console.log('UseEffect', firstBlock)
  //   if (firstBlock) {
  //     console.log('ставим', firstBlock.id)
  //     editor.setTextCursorPosition(firstBlock.id, "start"); // Устанавливаем курсор в начало первого блока
  //     console.log('поставили')
  //   }
  // }, [editor]);

  // Gets the default slash menu items merged with the multi-column ones.
  const getSlashMenuItems = useMemo(() => {
    return async (query) =>
      filterSuggestionItems(
        combineByGroup(
          getDefaultReactSlashMenuItems(editor),
          // getMultiColumnSlashMenuItems(editor)
        ),
        query
      );
  }, [editor]);

  return (
    <BlockNoteView
      editor={editor}
      theme="light"
      onChange={onChange}>
    </BlockNoteView>
  );
}

export default BlockNoteEditor;