const styles = [
  {
    selector: 'edge',
    style: {
      'background-color': '#11479e',
      // 'line-color': '#9dbaea',
      // 'target-arrow-color': '#9dbaea',
      'curve-style': 'bezier',
      // "label": "data(label)",
      // "width": 1,
      // "text-rotation": "autorotate",
      // "text-margin-x": "data(width)",
      // "text-margin-x": "-20",
      // 'text-wrap': 'wrap',
      // "font-size": '30',
      'target-arrow-shape': 'data(target_arrow_shape)',
      'source-arrow-shape': 'data(source_arrow_shape)',
    }
  },
  {
    selector: "edge[label]",
    css: {
      "label": "data(label)",
      "text-rotation": "autorotate",
      "text-margin-x": "data(xalign)",
      "text-margin-y": "data(yalign)"
    }
  },
  {
    selector: 'node',
    style: {
      'width': 'data(width)',
      'height': 'data(height)',
      // 'width': (ele) => {
      //   const length = ele.data().title.length
      //   console.log(length)
      //   return length * 10
      // },
      // 'width': 'data(width)',
      // 'height': 'label',
      // 'width': 'auto',
      // 'text-wrap': 'wrap',
      'line-height': 'data(line_height)',
      'shape': 'data(shape)',
      // 'shape': 'ellipse',
      // 'shape': 'octagon',
      // 'border-radius': '2',
      // 'label': 'data(title)',
      'content': 'data(title)',
      'padding': 'data(padding)',
      // 'color': 'data(text_color)',
      'text-wrap': 'wrap',
      'background-color': 'data(bg_color)',
      'corner-radius': 'data(corner_radius)',
      'border-width': 'data(border_width)',
      'border-color' : 'data(border_color)',
      // 'border-color' : 'var(--color-red-300)',
      'text-valign': 'center',
      'text-halign': 'center',
      'background-image': 'data(image_url)',
      // 'background-image-containment': 'over',
      'background-position-x': 'data(bg_x)',
      'background-position-y': 'data(bg_y)',
      // 'background-position-y': 'data(bg_y)',
      // 'shape-polygon-points':
      // 'background-position-x': '96%',
      // 'background-position-x': ( ele ) => {
      //   // const w = ele.outerWidth();
      //   // console.log('ele', ele.data())
      //   // console.log('elew', ele.width())
      //   // console.log('wR', w)
      //   return `${w}px`;
      //   // return 100
      // },
      // 'background-position-y': '4'
      // 'background-position-y': ( ele ) => {
      //   // const w = ele.width() - 10;
      //   // console.log('ele', ele.width())
      //   // console.log('w', w)
      //   return 2;
      // }
    }
  },
  {
    selector: ':parent',
    css: {
      'text-valign': 'top',
      'text-halign': 'center',
      'border-color' : 'data(border_color)',
      'padding': 20,
      'text-margin-y': '-10',
      // 'label': 'data(title)',
      // 'background-color': '#ffffff',
    }, classes: 'background'
  },
  {
    selector: ':child',
    style: {
      // 'corner-radius': "30",
    }
  },
  {
    selector: 'edge',
    style: {
      'width': '0.5'
    }
  },
]

export default styles
